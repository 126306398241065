import axios from 'axios';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React, { useContext, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Container, Spinner } from 'react-bootstrap';

import { getUser, renewTokens } from 'services/user';

import { useLocalStorage } from '@ui/hooks/useLocalStorage';
import { useTracking } from '@ui/hooks/useTracking';
import { useLanguage } from '@ui/hooks/useLanguage';

const UseUser = React.createContext(null);

const AppContainer = styled(Container)`
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: center;
`;

const getTokenExpDate = (token) => {
  if (token && token.split) {
    const encodedPayload = token.split('.')[1];
    const { exp } = JSON.parse(atob(encodedPayload));

    return exp * 1000;
  }

  return null;
};

const getJwtToken = async (tokens) => {
  try {
    const newTokenResponse = await renewTokens(tokens.refreshToken);

    if (newTokenResponse.success) {
      return newTokenResponse.data;
    }

    return null;
  } catch (e) {
    return null;
  }
};

export function UserSelectorContextProvider({ children }) {
  const [jwtTokens, setJwtTokens, clearJwtTokens] = useLocalStorage('jwt-tokens');
  const [user, setUser] = useState(null);
  const [LDProvider, setLDProvider] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { identify } = useTracking();
  const { language, setLanguage } = useLanguage();

  const userEmail = useMemo(() => {
    if (!user?.email) {
      return '';
    }

    if (user.email.length < 17) {
      return user.email;
    }

    return `${user.email.slice(0, 8)}...${user.email.slice(user.email.length - 6, user.email.length)}`;
  }, [user]);

  const userName = useMemo(() => {
    if (!user?.first_name || !user?.last_name) {
      return '-';
    }

    const name = `${user?.first_name} ${user?.last_name}`;

    if (name.length < 23) {
      return name;
    }

    return `${name.slice(0, 10)}...${name.slice(name.length - 10, name.length)}`;
  }, [user]);

  const hasCompletedBankDetails = useMemo(() => !!user?.withdrawal_details, [user]);
  const isUserProfileCompleted = useMemo(() => !!user?.profile_setup_completed, [user]);
  const isOnboardingPersonalDataCompleted = useMemo(
    () => !!user?.first_name && !!user?.last_name && !!user?.dob,
    [user]
  );

  const reloadUser = () =>
    getUser().then((data) => {
      setUser(data);
    });

  useEffect(() => {
    if (jwtTokens) {
      axios.defaults.headers.common.Authorization = jwtTokens.accessToken;
      axios.defaults.headers.common.IdToken = jwtTokens.idToken;

      const tokenExpiration = getTokenExpDate(jwtTokens.accessToken);

      if (tokenExpiration <= +new Date()) {
        getJwtToken(jwtTokens).then((newJwtTokens) => {
          if (newJwtTokens) {
            setJwtTokens(newJwtTokens);

            axios.defaults.headers.common.Authorization = newJwtTokens.accessToken;
            axios.defaults.headers.common.IdToken = newJwtTokens.idToken;
          }
        });
      } else {
        getUser()
          .then((data) => {
            setUser(data);
            setIsLoading(false);
            setIsLoggedIn(true);

            if (
              data?.preferred_language?.lang &&
              data?.preferred_language?.lang !== language?.lang
            ) {
              setLanguage(data.preferred_language);
            }
          })
          .catch(() => {
            clearJwtTokens();
            window.location.href = '/';
          });
      }
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [jwtTokens]);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          if (jwtTokens) {
            return getJwtToken(jwtTokens).then((newJwtTokens) => {
              if (newJwtTokens) {
                axios.defaults.headers.common.Authorization = newJwtTokens.accessToken;
                axios.defaults.headers.common.IdToken = newJwtTokens.idToken;

                originalRequest.headers['Authorization'] = newJwtTokens.accessToken;
                originalRequest.headers['IdToken'] = newJwtTokens.idToken;

                setJwtTokens(newJwtTokens);

                return axios(originalRequest);
              }
            });
          } else {
            return Promise.reject(error);
          }
        } else {
          return error.response;
        }
      }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user) {
      identify({
        data: {
          user_id: user.user_id,
          register_type: user.register_type,
          created_at: user.created_at,
          profile_setup_completed: user.profile_setup_completed,
          preferences: user.preferences?.personal?.values?.map((preference) => ({
            ...preference,
            options: [],
            selectedOption: preference?.options?.find(({ selected }) => selected)
          }))
        }
      });

      asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
        context: {
          kind: 'user',
          key: user.user_id,
          register_type: user.register_type,
          created_at: user.created_at,
          profile_setup_completed: user.profile_setup_completed,
          preferences: user.preferences?.personal?.values?.map((preference) => ({
            ...preference,
            options: [],
            selectedOption: preference?.options?.find(({ selected }) => selected)
          })),
          products: user.products,
          dob: user.dob,
          employment_status: user.employment_status,
          nationality: user.nationality,
          utm: user.utm
        }
      }).then((data) => {
        setLDProvider({
          Provider: data
        });
      });
    } else {
      asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID
      }).then((data) => {
        setLDProvider({
          Provider: data
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (isLoading) {
    return (
      <AppContainer fluid id="scrollable">
        <Spinner animation="grow" size="lg" />
      </AppContainer>
    );
  }

  let innerContent = children;

  if (LDProvider) {
    innerContent = <LDProvider.Provider>{children}</LDProvider.Provider>;
  }

  return (
    <UseUser.Provider
      value={{
        user,
        userEmail,
        userName,
        reloadUser,
        isLoggedIn,
        isLoading,
        jwtTokens,
        clearJwtTokens,
        hasCompletedBankDetails,
        isUserProfileCompleted,
        isOnboardingPersonalDataCompleted
      }}
    >
      {innerContent}
    </UseUser.Provider>
  );
}

export function useUserSelector() {
  const context = useContext(UseUser);

  if (!context) {
    throw new Error('useUserSelector must be used within a UserSelectorContextProvider');
  }

  return context;
}
