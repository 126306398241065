import { memo } from 'react';
import styled from 'styled-components';

import Icon from '../Icon';

const ToastContainer = styled.div`
  padding: ${(props) => (props.size === 'large' ? '20px 16px' : '4px')};
  display: flex;
  gap: 16px;
  border-radius: 12px;
  align-items: center;

  background: ${(props) => {
    switch (props.variant) {
      case 'success':
        return 'var(--green-10);';
      case 'error':
        return 'var(--red-10);';
      case 'warning':
        return 'var(--yellow-10);';
      case 'info':
        return 'var(--azure-10);';
      default:
        return 'inherit';
    }
  }};

  border: 1px solid
    ${(props) => {
      switch (props.variant) {
        case 'success':
          return 'var(--green-50);';
        case 'error':
          return 'var(--red-50);';
        case 'warning':
          return 'var(--yellow-50);';
        case 'info':
          return 'var(--azure-50);';
        default:
          return 'inherit';
      }
    }};
`;

const IconWrapper = styled.span`
  width: 32px;
  height: 32px;
`;

const ContentWrapper = styled.span`
  flex-grow: 1;
  font-size: 16px;
  text-align: left;

  color: ${(props) => {
    switch (props.variant) {
      case 'success':
        return 'var(--green-100);';
      case 'error':
        return 'var(--red-100);';
      case 'warning':
        return 'var(--yellow-100);';
      case 'info':
        return 'var(--azure-100);';
      default:
        return 'inherit';
    }
  }};
`;

const ActionsWrapper = styled.span``;

const CloseIconWrapper = styled.button`
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: var(--white);
  border: 0;
`;

const Toast = ({ onClose, children, variant, size = 'large', noIcon = false }) => {
  return (
    <ToastContainer variant={variant} size={size}>
      {!noIcon ? (
        <IconWrapper>
          {variant === 'success' ? (
            <Icon name="checkmark--filled" color="#16A34A" size={32} />
          ) : null}
          {variant === 'error' ? <Icon name="misuse" color="#B91C1C" size={32} /> : null}
          {variant === 'warning' ? (
            <Icon name="warning--alt--filled" color="#D5B81F" size={32} />
          ) : null}
          {variant === 'info' ? <Icon name="warning--filled" color="#1F73D5" size={32} /> : null}
        </IconWrapper>
      ) : null}
      <ContentWrapper variant={variant}>{children}</ContentWrapper>
      {onClose ? (
        <ActionsWrapper>
          <CloseIconWrapper onClick={onClose}>
            <Icon name="close" color="#626679" size={16} />
          </CloseIconWrapper>
        </ActionsWrapper>
      ) : null}
    </ToastContainer>
  );
};

export default memo(Toast);
